import React from 'react';

import { useQuery } from 'react-apollo';
import MediaQuery from 'react-responsive';
import { Redirect, useParams } from 'react-router';

import { useUserProvider } from '../context/user';
import { getDesignsForProduct } from '../apollo/queries/products';

import { desktop } from '../styles/constants';
import { GUEST_TOKEN_NAME } from '../constants';

import NoData from '../components/common/PageDetails/NoData';
import Loader from '../components/common/PageDetails/Loader';
import PageWrapper from '../components/common/PageDetails/PageWrapper';

import Product from '../components/Product';
import ModalPortal from '../components/common/Modals/ModalPortal';
import SubscriptionPopup from '../components/common/SubscriptionPopup';
import SubscriptionButton from '../components/common/Buttons/SubscriptionButton';

const token = localStorage.getItem(GUEST_TOKEN_NAME);

export default function RegularProductPage() {
  const { slug } = useParams<any>();

  const [isSubModal, toggleSubModal] = React.useState<boolean>(false);

  const {
    state: { currency, languages, currentLanguage: lang, isSubsButtonVisible },
  } = useUserProvider();

  const {
    Subscription: { subButton },
  } = languages[lang];

  const { data, error, loading, refetch } = useQuery(getDesignsForProduct, {
    variables: {
      slug,
      lang,
      token,
      currency,
    },
  });

  React.useEffect(() => {
    if (lang.length) refetch();
  }, [lang]);

  const subModalToggleHandler = () => toggleSubModal((prev) => !prev);

  if (loading) return <Loader />;
  if (error) return <Redirect to="/404" />;
  if (!data?.product) return <NoData title="Product not found" />;

  return (
    <PageWrapper>
      <Product product={data.product} />

      <MediaQuery query={desktop}>
        {isSubsButtonVisible ? (
          <SubscriptionButton hideModal={subModalToggleHandler}>
            {subButton}
          </SubscriptionButton>
        ) : (
          <></>
        )}
      </MediaQuery>

      {isSubsButtonVisible && (
        <ModalPortal
          color="transparent"
          isOpen={isSubModal}
          close={subModalToggleHandler}
        >
          <SubscriptionPopup hideModal={subModalToggleHandler} />
        </ModalPortal>
      )}
    </PageWrapper>
  );
}
