import React from 'react';
import { Query } from 'react-apollo';
import { Route, Redirect } from 'react-router-dom';

import { isAuthenticated } from '../apollo/queries/auth';

interface Result {
  isLoggedIn?: boolean;
}

interface Props {
  component: any;
  path: string;
  exact?: boolean;
  authState?: boolean;
}

export default ({
  path,
  exact = false,
  authState = false,
  component: Component,
}: Props) => (
  <Route
    path={path}
    exact={exact}
    render={(innerProps: any) => (
      <Query query={isAuthenticated}>
        {({ data }: { data: Result }) => {
          if (authState) {
            return data.isLoggedIn ? (
              <Component {...innerProps} />
            ) : (
              <Redirect to="/" />
            );
          }

          return !data.isLoggedIn ? (
            <Component {...innerProps} />
          ) : (
            <Redirect to="/" />
          );
        }}
      </Query>
    )}
  />
);
