import React from 'react';

import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import { useMutation } from 'react-apollo';

import { useUserProvider } from '../../../../context/user';
import { GUEST_TOKEN_NAME } from '../../../../constants';
import { updateCheckoutLine } from '../../../../apollo/mutations/checkout';
import { cocoRegular } from '../../../../styles/constants';

const mockArray: number[] = Array.from({ length: 10 }, (_, i) => i + 1);

const token = localStorage.getItem(GUEST_TOKEN_NAME);

interface Props {
  product: any;
  checkoutId: any;
}

export default function ProductQuantity({ product, checkoutId }: Props) {
  const {
    state: { currency, currentLanguage: lang },
  } = useUserProvider();

  const {
    size,
    cartId,
    quantity,
    productId,
    userDesignId,
    selectedImageGroupId,
  } = product || {};

  const [updateProductLineQuantityRequest, { error, loading }] = useMutation(
    updateCheckoutLine,
    {
      refetchQueries: ['Checkout'],
      onCompleted: () => {},
    },
  );

  const updateProductSizeHandler = async (event: any) => {
    const {
      target: { value: newQuantity },
    } = event;

    if (checkoutId) {
      const variables = {
        lang,
        token,
        currency,
        checkoutId,
        lines: [
          {
            size,
            lineId: cartId,
            quantity: Number(newQuantity) || 1,
            productId,
            userDesignId,
            selectedImageGroupId,
          },
        ],
      };

      await updateProductLineQuantityRequest({ variables });
    }
  };

  return (
    <Select
      size="small"
      value={quantity}
      variant="standard"
      MenuProps={{
        PaperProps: { style: { maxHeight: 200 } },
      }}
      sx={{
        ...cocoRegular,
        color: '#050505',
        fontSize: '0.75rem',
      }}
      onChange={updateProductSizeHandler}
    >
      {mockArray.map((value: number) => {
        return (
          <MenuItem
            key={value}
            value={value}
            sx={{ ...cocoRegular, color: '#050505', fontSize: '0.75rem' }}
          >
            {value}
          </MenuItem>
        );
      })}
    </Select>
  );
}
