import 'react-phone-input-2/lib/material.css';
import PhoneInput from 'react-phone-input-2';

import { useState } from 'react';
import { LoadingButton } from '@mui/lab';

import { isPossiblePhoneNumber } from 'libphonenumber-js';
import { TextField, Typography, FormControl } from '@mui/material';

import { useUserProvider } from '../../context/user';
import validateEmail from '../../utils/regExps/emailRegExp';
import ErrorMessage from '../common/Errors';
import { cocoRegular } from '../../styles/constants';

interface Props {
  changeCurrentComponentHandler: (name: string) => any;
}

export default function RegisterFrom({ changeCurrentComponentHandler }: Props) {
  const {
    state: { languages, currentLanguage },
  } = useUserProvider();

  const {
    specialEvents: { blackFriday },
  } = languages[currentLanguage];
  const { registration } = blackFriday;

  const [error, setError] = useState<string>('');
  const [isLoading, toggleLoading] = useState<boolean>(false);
  const [phoneFormat, setPhoneFormat] = useState<string>('');
  const [registerData, setRegisterData] = useState<any>({
    Voornaam: '',
    Achternaam: '',
    'E-mail': '',
    Telefoonnummer: '',
  });

  const btnDisabledConditions =
    isLoading ||
    error.length > 0 ||
    Object.values(registerData).filter((value: any) => value.length === 0)
      .length > 0;

  const registerDataChangeHandler = (e: any) => {
    const {
      target: { name, value },
    } = e;

    const newValue = value.trim() === '' ? '' : value;

    setRegisterData((prev: any) => {
      return {
        ...prev,
        [name]: newValue.length >= 50 ? prev[name] : newValue,
      };
    });
  };

  const clearErrorHandler = () => setError('');

  const phoneNumberHandler = (
    value: any,
    data: any,
    event: any,
    formattedValue: string,
  ) => {
    clearErrorHandler();

    setRegisterData((prev: any) => ({
      ...prev,
      Telefoonnummer: formattedValue,
    }));

    if (phoneFormat !== data.format) setPhoneFormat(data.format);
  };

  const registerHandler = async () => {
    try {
      toggleLoading(true);

      const isValidPhone = isPossiblePhoneNumber(registerData.Telefoonnummer);

      if (!isValidPhone) {
        toggleLoading(false);
        return setError('Invalid phone number');
      }

      const isValidEmail = validateEmail(registerData['E-mail']);

      if (!isValidEmail) {
        toggleLoading(false);
        return setError('Invalid email format');
      }

      await fetch(
        'https://api.mailcampaigns.nl/workflow/2355837462866/trigger',
        {
          method: 'POST',
          body: JSON.stringify(registerData),
          headers: {
            'Content-Type': 'text/plain',
          },
        },
      );

      changeCurrentComponentHandler('finish');
    } catch (err: any) {
      setError(err.message);
      toggleLoading(false);
    }

    return undefined;
  };

  return (
    <>
      <Typography variant="h6" sx={{ fontWeight: '700', fontSize: '1rem' }}>
        {registration.title1}
      </Typography>

      <Typography
        variant="h6"
        sx={{ margin: '1.5rem 0 2rem 0', fontSize: '0.7rem' }}
      >
        {registration.title2}
      </Typography>

      <FormControl
        fullWidth
        variant="filled"
        sx={{
          '& > div': {
            marginBottom: '0.6rem',
            '&:last-child': {
              marginBottom: '0',
            },
          },
          input: {
            paddingLeft: '1.5rem',
            borderRadius: '50px',
            backgroundColor: '#fff',
            outline: 'none',
            paddingBottom: '4px',
            paddingTop: '18px',
            ...cocoRegular,
            '&:autofill': {
              borderRadius: '50px',
            },
          },
          '.MuiFormLabel-root': {
            color: '#000 !important',
            fontSize: '0.6rem',
            left: '0.5rem',
            top: '-0.2rem',
            ...cocoRegular,
            fontWeight: '700',
          },
          '.MuiInputBase-root::before': {
            borderBottom: '0px !important',
          },
          '.MuiInputBase-root::after': {
            borderBottom: '0px !important',
          },
        }}
      >
        <TextField
          required
          disabled={isLoading}
          value={registerData.Voornaam}
          variant="filled"
          inputProps={{ maxLength: 50, autoComplete: 'off' }}
          name="Voornaam"
          label={registration.form.input1}
          fullWidth
          onInput={registerDataChangeHandler}
          onFocus={clearErrorHandler}
        />
        <TextField
          required
          disabled={isLoading}
          value={registerData.Achternaam}
          variant="filled"
          inputProps={{ maxLength: 50, autoComplete: 'off' }}
          name="Achternaam"
          label={registration.form.input2}
          fullWidth
          onInput={registerDataChangeHandler}
          onFocus={clearErrorHandler}
        />
        <TextField
          type="email"
          required
          disabled={isLoading}
          value={registerData['E-mail']}
          variant="filled"
          inputProps={{ maxLength: 50, autoComplete: 'off' }}
          name="E-mail"
          label={registration.form.input3}
          fullWidth
          onChange={registerDataChangeHandler}
          onFocus={clearErrorHandler}
        />

        <PhoneInput
          value={registerData.Telefoonnummer}
          country={'nl'}
          enableSearch
          countryCodeEditable={false}
          specialLabel=""
          inputStyle={{
            minHeight: '45px',
            width: '100%',
            paddingTop: '12px',
            paddingBottom: '8px',
            borderRadius: '50px',
            ...cocoRegular,
          }}
          onChange={phoneNumberHandler}
        />

        {error.length > 0 && (
          <ErrorMessage style={{ marginTop: '1.5rem' }}>{error}</ErrorMessage>
        )}

        <LoadingButton
          disabled={btnDisabledConditions}
          loading={isLoading}
          variant="contained"
          type="submit"
          sx={{
            color: '#fff',
            margin: '3rem 0 2rem 0',
            background:
              'transparent linear-gradient(264deg, #007AFF 0%, #5856D6 100%) 0% 0% no-repeat padding-box',
            boxShadow: '10px 10px 20px #00000040',
            borderRadius: '50px',
            fontSize: '0.7rem',
            '&:hover': {
              background:
                'transparent linear-gradient(264deg, #5856D6 0%, #007AFF 100%) 0% 0% no-repeat padding-box',
            },
          }}
          onClick={registerHandler}
        >
          {registration.form.btnText}
        </LoadingButton>
      </FormControl>
    </>
  );
}
