import React from 'react';
import { useMutation } from 'react-apollo';
import styled, { css } from 'styled-components';

import subscribeMutation from '../../apollo/mutations/subscription';

import { useUserProvider } from '../../context/user';

import { cocoRegular, desktop } from '../../styles/constants';

export default function NewsLetter() {
  const ref = React.useRef<HTMLInputElement>(null);

  const [msg, setMsg] = React.useState<string>('');

  const [newsSubscribeRequest, { loading }] = useMutation(subscribeMutation, {
    onCompleted: (data) => {
      if (data?.newsletterSubscribe) {
        const { errors } = data.newsletterSubscribe;

        if (errors?.length) setMsg(title2);
        else setMsg(title3);
      } else {
        setMsg(title2);
      }
    },
  });

  const {
    state: { languages, currentLanguage },
  } = useUserProvider();
  const { Footer } = languages[currentLanguage];
  const {
    subscribeBtn,
    inputPlaceHolder,
    subscribeFormTitle: { title1, title2, loading: loadingText, title3 },
  } = Footer;

  return (
    <Form
      onSubmit={async (e: any) => {
        e.preventDefault();

        if (ref?.current) {
          const variables = {
            input: {
              email: ref.current.value,
            },
          };

          await newsSubscribeRequest({ variables });
        }
      }}
    >
      <Label>
        {(() => {
          if (loading) return loadingText;
          if (msg) return msg;
          return title1;
        })()}
      </Label>

      <InputGroup>
        <Input
          ref={ref}
          type="email"
          name="newsLetter"
          required
          disabled={loading}
          placeholder={inputPlaceHolder}
          onChange={() => {
            if (msg) setMsg('');
          }}
        />

        <Button type="submit" role="none" disabled={loading}>
          {subscribeBtn}
        </Button>
      </InputGroup>
    </Form>
  );
}

const Form = styled.form`
  width: 100%;
`;

const textStyles = css`
  ${cocoRegular};
  color: #fff;
  font-size: 0.75rem;

  @media ${desktop} {
    font-size: calc(0.1em + 1vmin);
  }
`;

const Label = styled.p`
  margin: 1rem 0;
  ${textStyles};
  text-transform: uppercase;

  @media ${desktop} {
    margin-top: 0;
  }
`;

const InputGroup = styled.div`
  display: grid;
  grid-template-columns: 65% 35%;
  margin-top: 1rem;
`;

const Input = styled.input`
  flex-grow: 1;
  padding: 0.8rem;
  ${textStyles};
  border: 1px solid #ffffffcc;
  background-color: transparent;
`;

const Button = styled.button`
  padding: 0.8rem;
  ${textStyles};
  text-align: center;
  text-transform: uppercase;
  color: #010101;
  border: none;
  font-size: 0.75rem;
  background-color: #fff;

  :disabled {
    opacity: 0.75;
    cursor: not-allowed;
  }
`;
