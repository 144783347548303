import * as React from 'react';
import { NavLink } from 'react-router-dom';

import moment from 'moment-timezone';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import { cocoBold } from '../../../../styles/constants';

function isBeforeNovember20Amsterdam() {
  const currentDateTime = moment();
  const amsterdamTime = moment.tz('2024-11-20T00:00:00', 'Europe/Amsterdam');

  return currentDateTime.isBefore(amsterdamTime);
}

interface Props {
  collections: any[];
  closeDrawer: () => void;
}

export default function TopLevelCollectionsList({
  collections,
  closeDrawer,
}: Props) {
  const getCollectionPathname = (collection: any) => {
    if (collection.name.toLowerCase() === 'black friday') {
      if (!isBeforeNovember20Amsterdam()) return `/collection/black-friday/`;

      return `/special-event`;
    }

    return `/collection/${collection.collection.slug.toLowerCase()}`;
  };

  return (
    <List disablePadding>
      {collections
        .filter((collection: any) => !collection.enableSubmenu)
        .map((collection: any) => {
          const pathname = getCollectionPathname(collection);

          return (
            <ListItem
              key={collection.name}
              disablePadding
              sx={{ padding: '1rem 0', borderBottom: '1px solid gray' }}
            >
              <NavLink
                to={{ pathname }}
                onClick={closeDrawer}
                style={{
                  ...cocoBold,
                  color: '#000',
                  fontSize: '0.75rem',
                }}
              >
                {collection.name}
              </NavLink>
            </ListItem>
          );
        })}
    </List>
  );
}
