import React from 'react';
import { Redirect, useParams } from 'react-router-dom';

import { useQuery } from 'react-apollo';
import { Helmet } from 'react-helmet';

import Loader from '../components/common/PageDetails/Loader';
import NoData from '../components/common/PageDetails/NoData';
import Category from '../components/Category';
import PageWrapper from '../components/common/PageDetails/PageWrapper';
import { getProducts } from '../apollo/queries/products';
import { getCollection } from '../apollo/queries/collection';
import { useUserProvider } from '../context/user';
import { GUEST_TOKEN_NAME } from '../constants';

const token = localStorage.getItem(GUEST_TOKEN_NAME);

const descriptionFormatter = (value: string) =>
  value.replace(/(<([^>]+)>)/gi, '');

export default function CollectionAllCategories() {
  const { collectionName } = useParams<any>();

  const {
    state: { currency, currentLanguage: lang },
  } = useUserProvider();

  const {
    data: catalogInfo,
    error: catalogInfoError,
    loading: isCatalogInfoLoading,
  } = useQuery(getCollection, {
    variables: {
      lang,
      slug: collectionName.toLowerCase(),
      skip: !collectionName.length,
    },
  });

  const {
    data: productsInfo,
    error: productsError,
    loading: isProductsLoading,
    refetch: loadMoreProductsRequest,
  } = useQuery(getProducts, {
    skip: !catalogInfo?.collection,
    fetchPolicy: 'network-only',
    variables: {
      sizes: [],
      token,
      currency,
      categoryIds: [],
      collectionIds: [catalogInfo?.collection?.id],
    },
  });

  const isError: boolean = !!catalogInfoError || !!productsError;
  const isLoading: boolean = isCatalogInfoLoading || isProductsLoading;

  const pageTitle =
    `JorCustom-${catalogInfo?.collection?.name || ''}-${
      catalogInfo?.category?.name || ''
    }` || 'Jorcustom';
  const pageDescription = `${descriptionFormatter(
    catalogInfo?.collection?.description || '',
  )}-${descriptionFormatter(catalogInfo?.category?.description || '')}`;

  return (
    <PageWrapper>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
      </Helmet>

      {isLoading && <Loader />}

      {!isLoading && isError && <Redirect to="/404" />}

      {!isLoading && !isError && productsInfo?.products?.edges.length === 0 && (
        <NoData title="No products found" />
      )}

      {!isLoading && !isError && productsInfo?.products?.edges.length > 0 && (
        <Category
          productsInfo={productsInfo}
          categoryInfo={null}
          collectionInfo={catalogInfo?.collection}
          loadMoreProductsRequest={loadMoreProductsRequest}
        />
      )}
    </PageWrapper>
  );
}
