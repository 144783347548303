import { Button, FormControl, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useUserProvider } from '../../context/user';
import ErrorMessage from '../common/Errors';
import { cocoRegular } from '../../styles/constants';

export default function LoginFrom() {
  const {
    state: { languages, currentLanguage },
  } = useUserProvider();

  const {
    specialEvents: { blackFriday },
  } = languages[currentLanguage];
  const { login } = blackFriday;

  const router = useHistory();

  const [error, setError] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const clearErrorHandler = () => setError('');
  const inputChangeHandler = (e: any) => {
    const {
      target: { value },
    } = e;

    const newValue = value.trim() === '' ? '' : value;

    setPassword((prev) => (newValue.length >= 50 ? prev : newValue));
  };
  const submitHandler = () => {
    if (password.toLowerCase() === 'bwvip23')
      router.push('/collection/black-friday');
    else setError('Invalid password!');
  };

  return (
    <>
      <Typography variant="h6" sx={{ fontWeight: '700', fontSize: '1rem' }}>
        {login.title1}
      </Typography>

      <Typography variant="h6" sx={{ marginTop: '2rem', fontSize: '0.7rem' }}>
        {login.title2}
      </Typography>

      <Typography variant="h6" sx={{ fontSize: '0.7rem' }}>
        {login.title3}
      </Typography>

      <FormControl
        fullWidth
        variant="filled"
        sx={{
          margin: '5rem 0 1rem 0',
          input: {
            marginBottom: '1rem',
            paddingLeft: '1.5rem',
            paddingBottom: '4px',
            paddingTop: '18px',
            borderRadius: '50px',
            backgroundColor: '#fff',
            outline: 'none',
            '&:autofill': {
              borderRadius: '50px',
            },
            ...cocoRegular,
          },
          '.MuiFormLabel-root': {
            color: '#000 !important',
            fontSize: '0.6rem',
            left: '0.5rem',
            top: '-0.2rem',
            ...cocoRegular,
            fontWeight: '700',
          },
          '.MuiInputBase-root::before': {
            borderBottom: '0px !important',
          },
          '.MuiInputBase-root::after': {
            borderBottom: '0px !important',
          },
        }}
      >
        <TextField
          value={password}
          variant="filled"
          name="Password"
          label={login.form.input1}
          fullWidth
          onChange={inputChangeHandler}
          onFocus={clearErrorHandler}
        />

        {error.length > 0 && (
          <ErrorMessage style={{ marginBottom: '1rem' }}>{error}</ErrorMessage>
        )}

        <Button
          disabled={!password.length}
          variant="contained"
          type="submit"
          sx={{
            color: '#fff',
            fontSize: '0.7rem',
            background:
              'transparent linear-gradient(264deg, #007AFF 0%, #5856D6 100%) 0% 0% no-repeat padding-box',
            boxShadow: '10px 10px 20px #00000040',
            borderRadius: '50px',
            '&:hover': {
              background:
                'transparent linear-gradient(264deg, #5856D6 0%, #007AFF 100%) 0% 0% no-repeat padding-box',
            },
          }}
          onClick={submitHandler}
        >
          {login.btnText}
        </Button>
      </FormControl>
    </>
  );
}
