import React from 'react';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useMediaQuery } from '@mui/material';

import Price from './Price';
import Controls from './Controls';
import TrustIndicators from '../../../../common/TrustIndicators';
import ColorSelector from './ColorSelector';
import SizesSelector from './SizesSelector';

import { cocoRegular } from '../../../../../styles/constants';

interface Props {
  product: any;
}

export default function MainContent({ product }: Props) {
  const isMobile = useMediaQuery('(max-width:767px)');

  const { id, price, stockItems, sizeTableImage, colorSelectionProducts } =
    product || {};

  const productName = product?.name;

  const priceData = {
    price,
    isDiscount: Boolean(product?.discountPrice),
    discountPrice: product?.discountPrice?.localized,
  };

  const [selectedSize, setSelectedSize] = React.useState<string>('');
  const [isMissingSizeError, setMissingSizeError] =
    React.useState<boolean>(false);

  const sizeSelectHandler = (value: string) => {
    setSelectedSize(value);

    setMissingSizeError(false);
  };

  return (
    <>
      <Stack
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ 'h6, span': { fontSize: isMobile ? '0.75rem' : '1.13rem' } }}
      >
        <Typography
          variant="h6"
          sx={{
            ...cocoRegular,
            color: '#050505',
            fontWeight: 700,
            textTransform: 'uppercase',
          }}
        >
          {productName}
        </Typography>

        <Price priceData={priceData} priceColor="#454545" />
      </Stack>

      <ColorSelector
        productId={id}
        colorSelectionProducts={colorSelectionProducts}
      />

      <SizesSelector
        sizes={stockItems}
        sizesTable={sizeTableImage}
        selectedSize={selectedSize}
        isMissingSizeError={isMissingSizeError}
        sizeSelectHandler={sizeSelectHandler}
      />

      <Controls
        product={product}
        selectedSize={selectedSize}
        setMissingSizeError={setMissingSizeError}
      />

      <TrustIndicators />
    </>
  );
}
