import React from 'react';

import Box from '@mui/material/Box';
import SvgIcon from '@mui/material/SvgIcon';

import Drawer from './Drawer';

import { ReactComponent as MenuIcon } from '../../../assets/images/icons/header/icon_menu_white.svg';

interface Props {
  isWhiteColorHeader: boolean;
  openLocalesModal: () => void;
}

export default function MobileMenu({
  isWhiteColorHeader,
  openLocalesModal,
}: Props) {
  const [isDrawer, setDrawer] = React.useState<boolean>(false);

  return (
    <>
      <Box
        onClick={() => setDrawer(true)}
        sx={{
          mr: '20px',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <SvgIcon
          component={MenuIcon}
          inheritViewBox
          sx={{
            zIndex: 1120,
            height: '20px',
            path: {
              fill: isWhiteColorHeader ? '#0a0a0a' : '#fff',
            },
          }}
        />
      </Box>

      <Drawer
        isOpen={isDrawer}
        closeDrawer={() => setDrawer(false)}
        openLocalesModal={openLocalesModal}
      />
    </>
  );
}
