import React from 'react';
import styled from 'styled-components';

import {
  pink,
  white,
  black,
  cocoRegular,
  noTablet,
} from '../../../styles/constants';

interface Props {
  text: any;
  alignTo: string;
}

const ProductCardLabel = ({ text, alignTo }: Props) => (
  <Label alignTo={alignTo}>
    <Text>{text}</Text>
  </Label>
);

export default ProductCardLabel;

const Label = styled.div<{ alignTo: string }>`
  position: absolute;
  top: 0;
  ${({ alignTo }) => `${alignTo}: 0`};
  z-index: 1;
  background-color: ${({ alignTo }) => (alignTo === 'left' ? black : pink)};
  padding: 5px 10px;

  @media ${noTablet} {
    padding: 2px 5px;
  }
`;

const Text = styled.span`
  ${cocoRegular};
  color: ${white};
  font-size: calc(0.4em + 1vmin);
`;
