import PageTitle from '../components/common/PageDetails/PageTitle';
import PageWrapper from '../components/common/PageDetails/PageWrapper';
import CustomizedProductsListWrapper from '../components/Designs';

export default function Designs() {
  return (
    <PageWrapper>
      <PageTitle title="my-designs" />
      <CustomizedProductsListWrapper />
    </PageWrapper>
  );
}
