import { Fade, Stack } from '@mui/material';
import { useState } from 'react';
import styled from 'styled-components';
import moment from 'moment-timezone';
import { useHistory } from 'react-router-dom';

import LoginFrom from './LoginForm';
import RegisterFrom from './RegisterForm';
import WelcomeTitle from './WelcomeTitle';
import RegisterFinish from './RegisterFinish';

import { noDesktop } from '../../styles/constants';
import { useUserProvider } from '../../context/user';
import BlackFridayBgMobile from '../../assets/images/mocks/bf_bg_mobile.jpeg';
import BlackFridayBgDesktop from '../../assets/images/mocks/bf_bg_desktop.jpeg';
import { ReactComponent as CloseWhite } from '../../assets/images/icons/close_white.svg';

function isBeforeNovember17Amsterdam() {
  const currentDateTime = moment();
  const amsterdamTime = moment.tz('2023-11-17T18:00:00', 'Europe/Amsterdam');

  return currentDateTime.isBefore(amsterdamTime);
}

export default function SpecialEvent() {
  const router = useHistory();

  const {
    state: { languages, currentLanguage },
  } = useUserProvider();

  const {
    specialEvents: { blackFriday },
  } = languages[currentLanguage];
  const { registration } = blackFriday;

  const defaultComponent = isBeforeNovember17Amsterdam() ? 'welcome' : 'login';

  const [currentComponent, setComponent] = useState<string>(defaultComponent);

  const closeHandler = () => {
    if (currentComponent === 'register') setComponent('welcome');
    else router.push('/');
  };

  const changeCurrentComponentHandler = (name: string) => {
    setComponent(name);
  };

  return (
    <Wrapper>
      {currentComponent === 'welcome' && (
        <WelcomeTitle
          changeCurrentComponentHandler={changeCurrentComponentHandler}
        />
      )}

      <Fade key={currentComponent} in={currentComponent !== 'welcome'}>
        <FormContainer isHidden={currentComponent === 'welcome'}>
          {currentComponent !== 'welcome' && (
            <Stack
              display="flex"
              alignItems="flex-end"
              justifyContent="flex-end"
              sx={{ width: '100%', marginBottom: '2rem', padding: '0 0.5rem' }}
            >
              <Close onClick={closeHandler} />
            </Stack>
          )}

          <Stack
            display="flex"
            flexDirection="column"
            alignItems="center"
            width="100%"
            margin={currentComponent === 'finish' ? 'auto' : 0}
            justifyContent="center"
            padding="0 2rem 0 2rem"
          >
            {currentComponent === 'register' && (
              <RegisterFrom
                changeCurrentComponentHandler={changeCurrentComponentHandler}
              />
            )}

            {currentComponent === 'finish' && <RegisterFinish />}

            {currentComponent === 'login' && <LoginFrom />}
          </Stack>

          {currentComponent === 'register' && (
            <p>{registration.form.paragraph}</p>
          )}
        </FormContainer>
      </Fade>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  resize: both;
  background: url(${BlackFridayBgMobile});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  @media (min-width: 1200px) {
    background: url(${BlackFridayBgDesktop});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    resize: both;
  }
`;

const FormContainer = styled.div<{ isHidden: boolean }>`
  min-height: 55vh;
  min-width: 20vw;
  display: ${({ isHidden }) => (isHidden ? 'none' : 'flex')};
  flex-direction: column;
  align-items: center;
  padding: 0.5rem 0;
  background-color: #000000;
  overflow: hidden;

  p {
    margin-top: auto;
    color: #ffffff;
    font-size: 0.5rem !important;
  }

  h6 {
    color: #ffffff;
    text-transform: uppercase;
  }

  p,
  h6 {
    font-family: 'Coco Gothic', Arial, sans-serif;
  }

  @media (${noDesktop}) {
    min-height: 70vh;
    min-width: 90vw;
  }
`;

const Close = styled(CloseWhite)`
  width: 1.2rem;
  height: auto;
  cursor: pointer;
`;
