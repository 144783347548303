import React from 'react';
import { useLocation } from 'react-router-dom';
import styled, { keyframes, css } from 'styled-components';

import { cocoRegular } from '../../styles/constants';
import { useHeaderContext } from './Provider';

export default () => {
  const { pathname } = useLocation();

  const { newsLine } = useHeaderContext();

  const isVisible: boolean =
    pathname !== '/cart' && pathname !== '/checkout' && pathname !== '/account';

  const lineRef = React.useRef<HTMLDivElement>(null);
  const containerRef = React.useRef<HTMLDivElement>(null);

  const [animate, setAnimate] = React.useState(false);

  React.useEffect(() => {
    if (containerRef.current && lineRef.current) {
      if (lineRef.current.offsetWidth > containerRef.current.offsetWidth) {
        setAnimate(true);
      }
    }
  }, [newsLine]);

  if (!isVisible || !newsLine?.length) return null;

  return (
    <Container ref={containerRef}>
      <RunningLine animate={animate} ref={lineRef}>
        {newsLine}
      </RunningLine>
    </Container>
  );
};

const running = keyframes`
  0% {
    transform: translate(50%)
  }

  100% {
    transform: translate(-100%)
  }  
`;

const Container = styled.div`
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.15s ease-in-out;
  background-color: #0d0e0e;
`;

const animation = css`
  animation: ${running} 15s linear infinite;
`;

//  color: ${pink};
const RunningLine = styled.div<{ animate: boolean }>`
  margin: 0;
  padding: 3px 0;
  color: #fff;
  font-size: calc(0.1em + 1vmin);
  text-align: center;
  white-space: pre;
  ${cocoRegular};
  ${({ animate }) => animate && animation};
`;
