import React from 'react';

import { useQuery } from 'react-apollo';

import { useUserProvider } from '../context/user';
import { getCurrentUserInfo } from '../apollo/queries/account';

// @ts-ignore
export default function useCurrentUser() {
  const { dispatch } = useUserProvider();

  useQuery(getCurrentUserInfo, {
    onError: () => {
      console.log('guest');
      // @ts-ignore
      window.snaptr('init', '1aeeede7-89bf-484b-91e7-a53c7628cb2e', {
        user_email: 'INSERT_USER_EMAIL',
      });
    },
    onCompleted: (data) => {
      if (data?.me?.email) {
        const { me } = data || {};
        const { email, defaultBillingAddress } = me || {};
        const { phone } = defaultBillingAddress || {};

        if (email?.length > 0) {
          console.log('user');
          // @ts-ignore
          window.snaptr('init', '1aeeede7-89bf-484b-91e7-a53c7628cb2e', {
            user_email: email,
          });

          dispatch('SAVE_USER_DATA', {
            email,
            phone,
          });
        }
      }

      if (!data?.me?.email) {
        // @ts-ignore
        window.snaptr('init', '1aeeede7-89bf-484b-91e7-a53c7628cb2e', {
          user_email: 'INSERT_USER_EMAIL',
        });
      }
    },
  });

  return <></>;
}
