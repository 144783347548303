import styled from 'styled-components';
import { Stack, Typography } from '@mui/material';

import RightArrow from '../../assets/images/icons/arrows/arrow_right_white.png';
import { useUserProvider } from '../../context/user';

const columnStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
};

const titleStyles = {
  h5: {
    fontFamily: 'Coco Gothic, Arial, sans-serif',
    fontStyle: 'normal',
    fontWeight: '900',
    color: '#F7F7F7',
    textTransform: 'uppercase',
  },
};

interface Props {
  changeCurrentComponentHandler: (name: string) => any;
}

export default function WelcomeTitle({ changeCurrentComponentHandler }: Props) {
  const {
    state: { languages, currentLanguage },
  } = useUserProvider();

  const { specialEvents } = languages[currentLanguage];
  const { blackFriday } = specialEvents;

  const { welcome } = blackFriday;

  const clickHandler = () => {
    changeCurrentComponentHandler('register');
  };

  return (
    <Stack
      sx={{
        ...columnStyle,
        ...titleStyles,
      }}
    >
      <Typography variant="h5">{welcome.title1}</Typography>
      <Typography variant="h5">{welcome.title2}</Typography>

      <Button onClick={clickHandler}>
        <Arrow src={RightArrow} />
        <span>{welcome.btnText}</span>
      </Button>
    </Stack>
  );
}

const Button = styled.button`
  display: flex;
  align-items: center;
  position: relative;
  background: none;
  min-height: 3.5rem;
  border: none;
  font-size: 1rem;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
  color: #f7f7f7;
  padding: 0;
  font-family: Coco Gothic, Arial, sans-serif;
  overflow: hidden;
  transition: color 0.3s ease;

  &:hover {
    color: #f7f7f7;
  }

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0.5rem;
    left: 0;
    background: currentColor;
    transform-origin: bottom left;
    transform: scaleX(1);
    transition: transform 0.3s ease;
  }

  &:hover:before {
    transform-origin: bottom right;
    transform: scaleX(0);
  }
`;

const Arrow = styled.img`
  display: block;
  width: 1rem;
  height: auto;
  margin-right: 0.3rem;
  margin-bottom: 0.2rem;
  color: #fff;
`;
