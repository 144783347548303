import { Typography } from '@mui/material';
import { useUserProvider } from '../../context/user';

export default function RegisterFinish() {
  const {
    state: { languages, currentLanguage },
  } = useUserProvider();

  const {
    specialEvents: { blackFriday },
  } = languages[currentLanguage];
  const {
    registration: { finish },
  } = blackFriday;

  return (
    <>
      <Typography variant="h6" sx={{ fontSize: '0.9rem', fontWeight: 700 }}>
        {finish.title1}
      </Typography>

      <Typography variant="h6" sx={{ marginTop: '1rem', fontSize: '0.8rem' }}>
        {finish.title2}
      </Typography>
    </>
  );
}
